<template>
    <div>
        <div class="teeth-svg">

            <svg version="1.1" class="toomain" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 481 150"
                style="enable-background:new 0 0 481 150;" xml:space="preserve">

                <a href="#">
                    <g>
                        <path class="st0" d="M6.3,9.7C7.6,9.4,9,9.3,10.4,9.3c139.7,0,279.4,0,419.1,0c14.2,0.1,28.3-0.2,42.5,0.2c0,5.5-0.1,11,0,16.5
        c-6.6-3-14-6-21.4-4c-4.1,1.3-6.5,5.3-8.4,8.9c-2-3.6-4.4-7.7-8.7-8.8c-4.8-1.1-10.1-0.9-14.7,1c-2.9,1.2-4.4,4.2-6,6.6
        c-2.1-4.7-5.2-9.6-10.4-11.1c-4-0.8-8.6-0.5-12,2c-2.7,2.1-4.1,5.3-5.8,8.3c-4-5.8-10.4-11.6-18.1-10.1c-5.7,0.9-8.3,6.6-10.8,11.1
        c-2.4-5.9-6.1-12-12.3-14.5c-7.7-1.6-12.6,6.1-16.6,11.3c-0.9-3.2-1.6-6.7-3.9-9.2c-2.4-2.7-5.6-4.8-9-5.9c-3.1-1-6.3,0.3-9,1.7
        c-4.6,2.4-6.4,7.6-8.1,12.1c-4.1-5.2-10.4-9.7-17.3-8.5c-5.3,0.7-9.4,4.6-11.9,9.1c-2.8-6.6-8.3-13.4-16-13.7
        c-6.3,0.1-9.9,5.8-12.7,10.7c-2.7-4.8-6.1-10.6-12.4-10.6c-7.9,0.1-13.3,7.1-16.2,13.7c-2.5-4.2-6-8-11-8.9
        c-7.2-1.9-13.8,2.9-18.2,8.1c-2-5.1-4.3-11-10-12.9c-5.3-2.9-11.2,0.4-15.1,4.1c-3,2.6-4,6.6-5.2,10.2c-2.8-4.1-6-8.2-10.4-10.7
        c-2.9-1.3-6.6-1-9.1,1.1c-4.4,3.1-7.2,8-9.3,12.8c-2.4-4.5-5-10.1-10.6-11.1c-7.7-1.5-14.1,4.4-18.2,10.3c-1.9-3.8-3.9-8.1-8.2-9.7
        c-4.4-1.6-10-1.5-13.8,1.6c-3,2.2-4.7,5.5-6.2,8.8c-1.7-2.7-3.5-5.9-6.8-6.9c-5.1-1.7-11-2-16,0.2c-3.4,1.4-4.9,4.9-6.5,7.9
        c-2.1-3.5-4.5-7.5-8.6-8.9c-7.3-1.9-14.7,0.8-21.2,4.1C6,20.6,5.8,15.2,6.3,9.7z" />
                    </g>
                </a>
                <g>
                    <a href="#">
                        <g>
                            <path class="st1" d="M156.2,16.5c3.9-3.7,9.8-7,15.1-4.1c5.7,1.9,8.1,7.8,10,12.9c4.4-5.2,11-10,18.2-8.1c4.9,1,8.5,4.8,11,8.9
            c2.9-6.6,8.3-13.7,16.2-13.7c6.2,0.1,9.7,5.9,12.4,10.6c2.8-4.8,6.4-10.6,12.7-10.7c7.7,0.3,13.2,7.1,16,13.7
            c2.5-4.5,6.6-8.3,11.9-9.1c6.9-1.3,13.2,3.3,17.3,8.5c1.7-4.5,3.5-9.7,8.1-12.1c2.7-1.4,6-2.7,9-1.7c3.4,1.2,6.6,3.2,9,5.9
            c2.3,2.6,3,6,3.9,9.2c4-5.2,8.9-12.9,16.6-11.3c6.2,2.5,9.9,8.6,12.3,14.5c2.5-4.5,5.1-10.2,10.8-11.1c7.6-1.5,14,4.3,18.1,10.1
            c1.6-2.9,3-6.2,5.8-8.3c3.4-2.5,8-2.8,12-2c5.2,1.5,8.3,6.4,10.4,11.1c1.6-2.5,3-5.5,6-6.6c4.6-1.9,9.9-2.1,14.7-1
            c4.3,1.1,6.7,5.1,8.7,8.8c2-3.6,4.3-7.6,8.4-8.9c7.4-2.1,14.8,0.9,21.4,4c1,3.6,0.7,7.3,0.8,11c-0.1,2.4,0.3,5.1-1.4,7
            c-3.3,1.8-7.2,1.4-10.8,1.1c-3.5-0.5-5.9,2.5-8.9,3.5c-3.1,0.5-6-0.9-8.8-2c-9,3.5-18.9,3.2-28.3,1.7c-4.6,3.5-11.2,3.3-15.4-0.7
            c-2.4,1.3-4.6,3.3-7.5,3.2c-3.4,0.3-5.6-2.7-7.8-4.8c-5.6,2.3-11.8,6.3-17.9,3.8c-3.4-1.2-6.5-2.9-9.5-4.8
            c-4.6,3.8-10.3,7.4-16.6,6.6c-5.5-0.7-9.8-4.6-13.2-8.7c-2.9,4.8-7.2,9.5-12.8,10.8c-6.2,0.6-10.4-5-14.5-8.7
            c-1.8,2.4-3.8,4.9-6.8,5.8c-7.2,2.6-15.3,2.2-22.3-0.6c-1.9,2.8-5,4.2-8.3,4.4c-7.5,0.8-15.6,1.6-22.6-1.8
            c-6.7,3.3-14.4,2.6-21.6,2c-3.6-0.3-7.4-1.3-9.4-4.5c-9,3.5-20.5,3.7-28.1-3.1c-0.1-0.7-0.2-2.2-0.2-2.9
            c-3.9,3.7-7.8,8.4-13.3,9.6c-6.6,0-11.3-5.8-14.9-10.7c-3.8,4-8.1,8.1-13.9,8.5c-6.1,0.6-11.2-3.2-15.9-6.5
            c-4.3,2.1-8.4,4.9-13.2,5.5c-5.1-0.2-9.7-2.6-14.3-4.7c-2.1,2.1-4.4,5.1-7.7,4.8C83.6,51,81.3,49,79,47.7
            c-4.3,4-10.9,4.1-15.5,0.7c-9.4,1.5-19.3,1.8-28.3-1.8c-3.7,1.8-8.4,3.5-12,0.5c-4.8-4-11.4,0.2-16.6-3c-3.2-4.7-1-10.8-1.5-16.1
            c0.3-0.5,0.8-1.4,1.1-1.8c6.4-3.3,13.9-6,21.2-4.1c4.1,1.5,6.5,5.4,8.6,8.9c1.6-3,3.1-6.5,6.5-7.9c5-2.2,10.9-1.9,16-0.2
            c3.3,1,5,4.2,6.8,6.9c1.5-3.3,3.2-6.6,6.2-8.8c3.8-3.1,9.3-3.3,13.8-1.6c4.2,1.6,6.3,5.9,8.2,9.7c4.1-5.8,10.4-11.8,18.2-10.3
            c5.6,1,8.2,6.6,10.6,11.1c2.2-4.9,4.9-9.7,9.3-12.8c2.5-2.1,6.2-2.4,9.1-1.1c4.4,2.6,7.6,6.6,10.4,10.7
            C152.2,23.1,153.1,19.1,156.2,16.5z M162.9,14.5c-9.8,4.3-10.7,16.3-9.7,25.6c1.9,5.1,6,9,10.8,11.4c2.8,1.1,5.2-1.3,7.4-2.7
            c3.5-2.8,7.2-6.2,8.3-10.7c0.7-7.5-0.8-15.5-5.8-21.4C170.9,14,166.5,12.6,162.9,14.5z M224.1,14.8c-8.7,3.5-13.2,13.3-12.9,22.2
            c0.2,4.7-0.4,9.5,0.4,14.1c3.4,1.8,7.3,1.3,10.9,1.9c4.1,0.7,8.3,0,12.3-0.7c3.6-1,3-5.3,3.1-8.2c-0.3-8.2,1.3-17.4-3.9-24.5
            C231.9,16.2,228,13.5,224.1,14.8z M250,14.7c-3.1,0.8-5.1,3.5-6.8,6.1c-4.4,7-2.7,15.4-3.1,23.2c0,2.8-0.5,6.4,2.4,8
            c4.9,1.3,10.1,1.5,15.1,0.7c3-0.4,7.1,0.3,9.1-2.5c0.3-4.4,0-8.8,0.1-13.2c0.1-7.1-2.4-14.6-8-19.3
            C256.5,15.7,253.3,13.8,250,14.7z M304.6,16.2c-5.4,5.8-7,14.3-6.2,21.9c1,4.3,4.4,7.5,7.7,10.2c2.3,1.6,4.8,4.3,7.8,3.1
            c4.9-2.3,8.7-6.5,11-11.3c0.2-8.2,0.6-18.2-6.8-23.6C314.5,13,308.5,12.8,304.6,16.2z M133.8,18.4c-8.5,5.2-11,16.1-9.4,25.4
            c2.5,3,6.4,4.5,10.1,5.3c5.6,1,11-2.3,14.1-6.8c2.9-3,1.9-7.5,1.6-11.3c-2.2-4.7-5.2-9.3-9.6-12.2C138.6,17.4,136,16.9,133.8,18.4
            z M184.3,25.8c-3.8,5.9-1.3,13.3-2.2,19.8c2.3,2.3,5.3,3.7,8.4,4.2c6,0.8,12.5,0.7,17.8-2.6c0.3-5.8,0.4-11.6-0.1-17.3
            c-0.4-4.5-3.5-8.2-7.4-10.1C194.7,17.5,187.8,20.6,184.3,25.8z M338.7,18.1c-5,2.5-8.2,7.4-10.7,12.3c-0.9,3.1-0.3,6.4-0.4,9.6
            c2,2.7,3.8,5.6,6.9,7.2c6,4,14.5,1.8,19.1-3.4c1.5-7.9-0.1-16.9-6.1-22.8C345.2,18.9,341.9,16.2,338.7,18.1z M270.7,26.4
            c-2.1,6.7-1.4,14-0.8,21c5.8,3.3,12.9,3.4,19.2,2.1c3-1,7.6-2.4,6.8-6.5c-0.2-6.9,1.5-15.6-5-20.2C285.1,17,273.6,18.3,270.7,26.4
            z M99.3,25.8c-4.8,4.4-4.9,11.5-4.3,17.5c2.1,1.9,4.8,2.9,7.4,4.1c2.8,0.4,5.7,1.4,8.5,0.4c3.7-1.2,7.9-2.6,10.1-6
            c0.4-3.6,0.7-7.6-1.2-10.8c-1.9-3.8-4-8.3-8.2-9.9C106.9,19.5,102.5,22.7,99.3,25.8z M366.5,20.9c-4.3,1.6-6.4,6.1-8.4,10
            c-2,3.4-1.2,7.6-1.2,11.3c2.5,2.2,5.4,4.1,8.7,5.1c6,2.3,12.6-0.6,17.6-4c0.1-5.6,0.6-12-3.4-16.5
            C376.3,23.4,371.7,19.4,366.5,20.9z M66.3,34.6c-0.9,4.2-0.3,8.6-0.4,12.9c3.9,1.4,8.3,1.3,11.5-1.5c-1.1-1-2.1-2-3.1-3
            c2.9-1.8,5.1,1.5,7,3.2c2.3,2.4,6.4,3.4,8.8,0.6c2.7-2.1,2-5.7,2.1-8.7c0.1-5-1.7-10-4.6-14.1c-2.5-3.4-7.2-4.1-11.2-3.2
            C70.7,22.9,68,29.1,66.3,34.6z M390.3,24c-4.3,5.7-5.3,13.1-4.5,20c1.1,2.9,4.5,5.6,7.6,4.1c3.8-1.1,5.4-6.4,9.9-5.7
            c-0.4,1.6-1.7,2.6-2.7,3.8c3.3,2.6,7.5,2.7,11.3,1.3c-0.2-5.2,0.8-10.6-1.2-15.5c-1.6-4.4-4.1-8.9-8.5-10.9
            C398.2,19.7,393,20.4,390.3,24z M7.7,28c0.2,4.5-0.3,9.1,0.3,13.6c1.6,1.6,3.9,1.7,6,1.5c0.1-0.7,0.3-2.2,0.4-2.9
            c3.6,1.7,7.5,2.9,10.7,5.3c2.1,1.8,5.3,1.2,7.4-0.2c2.3-1.7,1.2-4.9,1.5-7.3c0.5-5.7-2.3-12.5-8.3-14.2C19.5,23.1,13,24.7,7.7,28z
             M40.8,27.4c-3.2,5.1-5,11.3-3.9,17.4c5.8,2.7,12.5,2.7,18.8,2.6l0.7-0.5c2.2-0.1,4.4-0.2,6.6-0.4c-0.1-6.8,1.6-15-3.5-20.5
            C53.8,23.3,45.3,22.1,40.8,27.4z M420.2,24.7c-7.3,4.6-5.1,14.4-5.2,21.6c8.4,1.1,17.3,1.9,25.4-1.2c1.6-0.6,0.7-2.8,1-4.1
            c0.1-4.8-1.7-9.6-4.2-13.6C433.1,22.3,425.8,23.8,420.2,24.7z M448.5,25.9c-5.1,4.3-4.8,11.6-4.4,17.7c1.1,2.9,4.7,3,7.4,3.1
            c3.5-3.3,8.1-4.8,12.5-6.4c-0.1,0.8-0.2,2.3-0.2,3c2-0.4,4.9,0.2,6.3-1.8c0.7-4.5,0.1-9,0.2-13.6c-3.6-2.2-7.6-3.5-11.7-4.1
            C455.1,23.6,451,23.1,448.5,25.9z" />
                        </g>
                    </a>
                    <path class="st1" d="M75.6,111.7c2.1-0.8,5.3-5.5,6.9-1.8c-3,1.5-5.4,3.9-7,6.9c-1.7-2.2-3.4-4.2-5-6.4
        C72.3,110.2,73.9,111.2,75.6,111.7z" />
                </g>
                <a href="#">
                    <g>
                        <path class="comon st2" @click="reply_click(13)"
                            :class="[tooth_num.includes(13)==true ?  'activeClass comon st3 ' : ' comon st3']" id="13"
                            d="M162.9,14.5c3.6-1.9,8.1-0.5,10.9,2.1c5,5.9,6.5,13.9,5.8,21.4c-1.1,4.5-4.7,7.9-8.3,10.7
        c-2.2,1.4-4.5,3.8-7.4,2.7c-4.8-2.3-8.9-6.3-10.8-11.4C152.2,30.9,153.1,18.8,162.9,14.5z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path @click="reply_click(11)"
                            :class="[tooth_num.includes(11)==true ?  'activeClass comon st3 ' : ' comon st3']" id="11"
                            d="M224.1,14.8c4-1.2,7.9,1.4,9.8,4.8c5.2,7.1,3.6,16.3,3.9,24.5c-0.1,2.9,0.5,7.2-3.1,8.2
        c-4,0.7-8.2,1.4-12.3,0.7c-3.7-0.5-7.5-0.1-10.9-1.9c-0.8-4.6-0.1-9.4-0.4-14.1C210.9,28,215.4,18.2,224.1,14.8z" />
                        <path @click="reply_click(21)"
                            :class="[tooth_num.includes(21)==true?  'activeClass comon st3 ' : ' comon st4']" id="21" d="M250,14.7c3.3-0.9,6.5,1,8.8,3c5.5,4.7,8,12.1,8,19.3c-0.1,4.4,0.1,8.8-0.1,13.2c-2,2.8-6.1,2.1-9.1,2.5
        c-5,0.8-10.2,0.5-15.1-0.7c-2.8-1.6-2.3-5.3-2.4-8c0.3-7.7-1.3-16.2,3.1-23.2C244.9,18.2,246.9,15.4,250,14.7z" />
                        <path @click="reply_click(43)"
                            :class="[tooth_num.includes(43)==true ?  'activeClass comon st5 ' : ' comon st5']" id="43"
                            d="M163.9,102.6c2.9-0.8,5.4,1.5,7.6,3.2c3.1,2.7,6.1,5.6,8.2,9.2c-0.1,6.1-0.3,12.8-4.5,17.7c-2.2,3-6.3,4-9.7,3
        c-5.8-2-10.5-6.8-12.3-12.6c0-4.3-1.2-9.1,1.4-12.9C156.7,106.7,160,103.8,163.9,102.6z" />
                        <path @click="reply_click(42)"
                            :class="[tooth_num.includes(42)==true ?  'activeClass comon st5 ' : ' comon st6']" id="42"
                            d="M192.5,104.1c4.7-0.4,9.5-0.3,14.1,0.8c1.3,0,1.9,0.7,1.8,1.9c0.2,6.8,0.3,13.8-2.2,20.3
        c-1,3.4-4.6,5.1-7.7,6.2c-5.1,0.9-10.3-1.9-13.4-5.9c-4.1-5.8-2.8-13.2-2.9-19.8C184.4,104.4,189,104.8,192.5,104.1z" />
                        <path @click="reply_click(41)"
                            :class="[tooth_num.includes(41)==true ?  'activeClass comon st7 ' : ' comon st7']" id="41"
                            d="M211.2,105.7c8.5-2.8,17.9-3.1,26.6-0.7c0,5.7,0.2,11.4-0.1,17c-1.1,5.3-4.8,10.6-10.1,12.4
        c-3.1,0.4-5.9-1.6-8.1-3.5c-3.4-3.3-5.8-7.4-7.3-11.9C210.5,114.8,211.4,110.1,211.2,105.7z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st8" @click="reply_click(23)"
                            :class="[tooth_num.includes(23)==true?  'activeClass comon st3 ' : ' comon st3']" id="23" d="M304.6,16.2c3.9-3.4,9.8-3.2,13.6,0.3c7.3,5.4,7,15.5,6.8,23.6c-2.3,4.9-6,9.1-11,11.3
        c-3.1,1.2-5.6-1.5-7.8-3.1c-3.3-2.7-6.7-6-7.7-10.2C297.6,30.4,299.2,22,304.6,16.2z" />
                        <path class="comon st9" id="14" @click="reply_click(14)"
                            :class="[tooth_num.includes(14)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M133.8,18.4c2.1-1.6,4.8-1,6.8,0.4c4.4,2.9,7.4,7.5,9.6,12.2c0.3,3.7,1.3,8.3-1.6,11.3
        c-3.1,4.4-8.6,7.8-14.1,6.8c-3.7-0.8-7.6-2.3-10.1-5.3C122.8,34.5,125.3,23.6,133.8,18.4z" />

                        <path class="comon st10" id="12" @click="reply_click(12)"
                            :class="[tooth_num.includes(12)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M184.3,25.8c3.6-5.1,10.4-8.2,16.6-6c3.9,1.9,7,5.6,7.4,10.1c0.4,5.8,0.3,11.6,0.1,17.3
        c-5.3,3.3-11.8,3.4-17.8,2.6c-3.2-0.5-6.2-1.9-8.4-4.2C182.9,39,180.5,31.7,184.3,25.8z" />


                        <path class="comon st11" id="24" @click="reply_click(24)"
                            :class="[tooth_num.includes(24)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M338.5,18.1c3.2-1.9,6.5,0.8,8.8,3c5.9,5.9,7.6,14.9,6.1,22.8c-4.6,5.1-13.1,7.4-19.1,3.4
        c-3-1.6-4.9-4.5-6.9-7.2c0.1-3.2-0.5-6.5,0.4-9.6C330.3,25.5,333.5,20.6,338.5,18.1z" />
                        <path class="comon st12" id="22" @click="reply_click(22)"
                            :class="[tooth_num.includes(22)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M270.7,26.4c2.9-8.1,14.4-9.4,20.2-3.7c6.6,4.7,4.8,13.3,5,20.2c0.8,4.1-3.8,5.5-6.8,6.5
        c-6.4,1.3-13.4,1.2-19.2-2.1C269.3,40.4,268.6,33.1,270.7,26.4z" />
                        <path class="comon st13" id="44" @click="reply_click(44)"
                            :class="[tooth_num.includes(44)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M131.1,106c6.1-3.2,13.9-0.8,17.4,5c2.5,2.4,1.8,5.9,1.9,9c0.4,4-2.5,7.2-4.9,10c-3,3.5-8.5,5-12.6,2.3
        c-4.1-3-8.4-6.9-8.8-12.3c0-3.1-0.8-6.6,0.9-9.5C126.7,108.5,128.9,107,131.1,106z" />
                        <path class="comon st14" id="34" @click="reply_click(34)"
                            :class="[tooth_num.includes(34)==true ?  'activeClass comon st3 ' : ' comon st3']"
                            d="M335.9,105.4c4.5-1.8,9.8-0.6,13.7,2.1c2.1,1.5,4.3,3.6,4.3,6.4c0.3,4.8-0.1,10.2-3.7,13.7
        c-2.7,2.6-5.6,6.2-9.7,5.9c-6.2,0.3-10.4-5.6-12.7-10.6c-0.1-4-1.3-8.8,1.7-12C331.1,108.6,333.2,106.6,335.9,105.4z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st15" @click="reply_click(15)"
                            :class="[tooth_num.includes(15)==true ?  'activeClass comon st3 ' : ' comon st3']" id="15"
                            d="M99.3,25.8c3.3-3.1,7.6-6.3,12.3-4.8c4.2,1.6,6.4,6.1,8.2,9.9c1.9,3.3,1.6,7.2,1.2,10.8
        c-2.3,3.4-6.4,4.9-10.1,6c-2.8,1-5.7,0-8.5-0.4c-2.6-1.1-5.3-2.2-7.4-4.1C94.4,37.3,94.5,30.1,99.3,25.8z" />
                        <path class="comon st16" id="25" @click="reply_click(25)"
                            :class="[tooth_num.includes(25)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M366.5,20.9c5.2-1.5,9.9,2.5,13.3,5.9c4,4.5,3.5,10.9,3.4,16.5c-5,3.4-11.5,6.3-17.6,4
        c-3.3-1-6.1-2.8-8.7-5.1c0-3.8-0.8-7.9,1.2-11.3C360,27,362.1,22.5,366.5,20.9z" />
                        <path class="comon st17" id="16" @click="reply_click(16)"
                            :class="[tooth_num.includes(16)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M66.3,34.6c1.7-5.5,4.4-11.7,10.1-13.8c3.9-0.9,8.6-0.2,11.2,3.2c2.9,4.1,4.7,9,4.6,14.1
        c-0.1,3,0.7,6.6-2.1,8.7c-2.4,2.8-6.6,1.8-8.8-0.6c-2-1.7-4.2-5-7-3.2c1,1,2.1,2,3.1,3c-3.3,2.8-7.7,2.9-11.5,1.5
        C66,43.2,65.4,38.8,66.3,34.6z" />
                        <path class="comon st18" id="28" @click="reply_click(28)"
                            :class="[tooth_num.includes(28)==true?  'activeClass comon st3 ' : ' comon st3']" d="M448.5,25.9c2.6-2.8,6.6-2.3,10-2.1c4.1,0.6,8.1,2,11.7,4.1c-0.1,4.5,0.4,9.1-0.2,13.6
        c-1.4,1.9-4.2,1.3-6.3,1.8c0.1-0.8,0.2-2.3,0.2-3c-4.4,1.6-9,3.2-12.5,6.4c-2.7-0.1-6.3-0.2-7.4-3.1
        C443.6,37.5,443.4,30.2,448.5,25.9z" />
                        <path class="comon st19" id="33" @click="reply_click(33)"
                            :class="[tooth_num.includes(33)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M298.4,114.8c3-4.8,7.3-9.2,12.3-11.9c2.5-1.4,5.2,0.1,7.3,1.5c3.2,2,5.3,5.2,7,8.4c-0.3,4.4,1,9.3-1.4,13.3
        c-2.3,4-5.9,7.3-10.1,9.1c-3.3,1.4-7.6,0.8-10-2.1C298.8,128.3,298.1,121.2,298.4,114.8z" />
                        <path class="comon st20" id="45" @click="reply_click(45)"
                            :class="[tooth_num.includes(45)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M106.7,104.3c5.4-0.9,10.7,2.8,13.6,7.2c1.5,1.8,1,4.4,1,6.5c-0.1,5.3-3.9,9.7-8,12.7
        c-2.7,2.6-7.3,2.7-10.3,0.6c-3.4-2.1-6.4-5-8-8.7c-0.4-3.4-0.3-6.9-0.1-10.3C97.1,107.8,102,105.3,106.7,104.3z" />
                        <path class="comon st21" id="35" @click="reply_click(35)"
                            :class="[tooth_num.includes(35)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M366,104.8c6.2-2,13.4,1.7,16.8,7c0.7,3.5,0.4,7.2,0.2,10.8c-2,4.5-5.9,8.2-10.4,10
        c-5.7,1.6-10.2-3.2-13.3-7.3c-2.8-3.6-2.9-8.5-2.1-12.8C359.1,109,362.2,106.1,366,104.8z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st22" id="26" @click="reply_click(26)"
                            :class="[tooth_num.includes(26)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M390.3,24c2.7-3.6,7.9-4.2,11.9-2.9c4.4,2,7,6.5,8.5,10.9c2,4.9,1,10.3,1.2,15.5c-3.8,1.4-8,1.3-11.3-1.3
        c1-1.2,2.4-2.2,2.7-3.8c-4.4-0.7-6,4.6-9.9,5.7c-3.2,1.5-6.6-1.2-7.6-4.1C385,37,386,29.7,390.3,24z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st23" id="18" @click="reply_click(18)"
                            :class="[tooth_num.includes(18)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M7.7,28c5.3-3.3,11.7-4.9,18-4.1c6,1.6,8.7,8.4,8.3,14.2c-0.3,2.4,0.9,5.6-1.5,7.3c-2.1,1.4-5.4,2-7.4,0.2
        c-3.2-2.5-7.1-3.7-10.7-5.3c-0.1,0.7-0.3,2.2-0.4,2.9c-2.1,0.2-4.4,0.1-6-1.5C7.4,37.1,7.9,32.5,7.7,28z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st24" id="17" @click="reply_click(17)"
                            :class="[tooth_num.includes(17)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M40.8,27.4c4.6-5.3,13-4.1,18.8-1.6c5.1,5.5,3.4,13.7,3.5,20.5c-2.2,0.2-4.4,0.4-6.6,0.4l-0.7,0.5
        c-6.3,0.1-13,0.1-18.8-2.6C35.8,38.7,37.6,32.5,40.8,27.4z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st25" id="27" @click="reply_click(27)"
                            :class="[tooth_num.includes(27)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M420.2,24.7c5.6-0.9,12.9-2.4,17,2.7c2.5,4.1,4.3,8.8,4.2,13.6c-0.3,1.3,0.6,3.5-1,4.1
        c-8.1,3.1-17,2.3-25.4,1.2C415,39.1,412.9,29.3,420.2,24.7z" />

                    </g>
                </a>

                <a href="#">
                    <g>

                        <path class="comon st26" id="31" @click="reply_click(31)"
                            :class="[tooth_num.includes(31)==true ?  'activeClass comon st3 ' : ' comon st3']" d="M240.2,105c8.6-2.5,18-2.1,26.5,0.6c0,3.5,0,6.9,0,10.4c-2,7-5.6,14.3-12.4,17.6c-2.9,1.8-6.4,0.2-8.6-1.9
        c-2.6-2.7-4.8-6-5.5-9.7C240.1,116.3,240.3,110.7,240.2,105z" />
                    </g>
                </a>
                <g>
                    <a href="#">
                        <g>
                            <path class="st27" d="M151.4,110.3c3.2-5.2,8.4-10.7,15.1-9.9c5.5,1.2,9.1,6,13.1,9.7c0.1-1.4,0.3-2.9,0.4-4.3
            c2.6-1.6,5.3-3.1,8.3-3.5c7.2-1,14.8-1,21.8,1.3c9.1-3.3,19.2-2.9,28.6-0.8c9.7-1.8,20.2-2.7,29.6,1c1-0.3,2.1-0.6,3.2-0.9
            c6.4-0.7,12.9-0.5,19.3-0.1c3.7,0.9,8.1,2.7,8.1,7.1c3.7-3.7,7.4-8.2,12.7-9.5c6.7-0.8,11.8,4.8,15.1,9.8c3.5-4.1,8.1-8.2,13.9-8
            c6-0.2,11.3,3.5,15.2,7.7c3.5-4.2,8.1-8.2,13.9-8.2c5.8,0.3,11,3.6,15,7.6c2-1.8,4-4,6.9-4.1c3.9-0.7,7.7,1,11.4,2
            c2.1,0,4.2-1,6.3-0.8c1.9,0.9,3.3,2.6,4.8,4.1c4.3-3.4,10.1-2.8,14.6-0.2c5-2.8,10.7-1.6,15.2,1.5c4.6-0.8,9.4-0.9,13.9,0.3
            c4.1-1.5,9.5-3.2,13.1,0.3c3.4,5,2,11.7,0.2,17c-0.3,0.3-1,0.8-1.3,1.1c-5.8,5-14.8,6.4-21.6,2.8c-3.2-1.7-4.8-5.2-6.3-8.3
            c-3.5,5-8.7,9.9-15.2,9.6c-5.6,0.6-9.2-4.4-12.3-8.3c-1.4,2.8-2.5,6.3-5.8,7.3c-5.7,1.9-12.1,1.7-17.9,0.2
            c-3.4-0.8-5.2-4.1-6.9-6.8c-3.6,3.5-7.6,7.7-13.1,7.7c-6.8,0.5-11.3-5.6-14.9-10.5c-3.2,5.2-7.7,10.4-14,11.6
            c-6.7,0.8-11.8-4.5-15.2-9.6c-3,6-8.6,10.2-14.9,12.3c-1.4,0.1-2.8,0.1-4.1,0c-5.5-1.9-8.8-6.9-10.6-12.2c-3.1,5.1-8.2,9-14.2,9.7
            c-3.8,0.2-7.5-1.3-10.3-3.7c-3-2.5-3.6-6.7-4.7-10.2c-3.1,6.3-7.8,13-15.1,14.6c-6.2,0.5-10.2-5.2-13.5-9.6
            c-3,4.6-7.2,10-13.3,9.5c-7.2-1.6-12.1-8.1-15-14.4c-1.2,5.2-3.7,10.8-9.3,12.5c-7.8,3.4-16-2-20.2-8.4
            c-1.7,5.6-5.5,10.9-11.6,12.3c-1.1,0.1-2.1,0.1-3.2,0c-6.3-2-11.6-6.5-14.8-12.3c-3.3,4.7-7.8,9.9-14.2,9.6
            c-6.8-0.5-11.7-6.3-15.2-11.7c-3.3,5.1-8,10.9-14.7,10.6c-5.5,0-9.5-4.3-13.3-7.8c-1.6,2.4-3,5.4-5.9,6.4
            c-5.7,1.9-12,2.1-17.9,0.5c-3.5-0.8-5.1-4.5-6.9-7.2c-3,3.3-6,7.7-11,7.8c-7.1,0.7-13.3-4.3-16.5-10.2c-1,5-4.8,9.3-9.9,10.5
            c-6.9,1.4-15.1-0.1-19.4-6.3c-2.4-5-2.9-11.4,0.3-16.1c3.7-3.4,9-1.6,13.1-0.2c4.5-1.3,9.3-1.2,13.9-0.3
            c4.4-3.3,10.3-4.3,15.3-1.6c4.5-2.7,10.3-3.2,14.6,0.2c1.4-1.5,2.9-2.9,4.3-4.4c2.1,0.3,4.2,0.9,6.3,1.1c3.9-1,7.8-2.9,12-2.1
            c2.8,0.2,4.7,2.4,6.7,4.1c4-4.1,9.2-7.4,15-7.7c5.7,0.1,10.4,4.1,13.9,8.3c3.8-4.4,9.1-7.9,15.1-7.8
            C143.3,102,148,106.1,151.4,110.3z M163.9,102.6c-3.9,1.2-7.2,4.1-9.3,7.5c-2.6,3.8-1.4,8.6-1.4,12.9c1.8,5.8,6.5,10.6,12.3,12.6
            c3.4,1.1,7.5,0,9.7-3c4.2-4.9,4.4-11.6,4.5-17.7c-2-3.6-5.1-6.5-8.2-9.2C169.3,104.1,166.8,101.8,163.9,102.6z M298.4,114.8
            c-0.2,6.4,0.4,13.5,5,18.4c2.4,2.8,6.7,3.5,10,2.1c4.3-1.8,7.9-5.1,10.1-9.1c2.5-4,1.1-8.8,1.4-13.3c-1.7-3.3-3.8-6.4-7-8.4
            c-2.1-1.4-4.9-2.9-7.3-1.5C305.6,105.7,301.4,110,298.4,114.8z M106.7,104.3c-4.7,1-9.6,3.5-11.7,8c-0.3,3.4-0.4,6.9,0.1,10.3
            c1.6,3.7,4.6,6.7,8,8.7c3,2.1,7.6,2,10.3-0.6c4-3.1,7.9-7.4,8-12.7c-0.1-2.2,0.5-4.7-1-6.5C117.4,107.1,112.1,103.3,106.7,104.3z
             M192.5,104.1c-3.4,0.7-8,0.3-10.2,3.6c0.1,6.6-1.2,14,2.9,19.8c3.1,4,8.3,6.9,13.4,5.9c3.1-1.1,6.7-2.8,7.7-6.2
            c2.5-6.5,2.4-13.5,2.2-20.3c0.1-1.3-0.5-1.9-1.8-1.9C202,103.9,197.2,103.7,192.5,104.1z M211.2,105.7c0.2,4.5-0.7,9.1,1,13.4
            c1.5,4.4,3.9,8.6,7.3,11.9c2.3,1.9,5,3.9,8.1,3.5c5.3-1.8,9-7.1,10.1-12.4c0.3-5.7,0.1-11.4,0.1-17
            C229.1,102.5,219.7,102.8,211.2,105.7z M240.2,105c0,5.7-0.2,11.4,0,17c0.7,3.7,2.9,7,5.5,9.7c2.2,2.1,5.7,3.8,8.6,1.9
            c6.8-3.3,10.4-10.7,12.4-17.6c0-3.5,0-6.9,0-10.4C258.3,102.8,248.9,102.5,240.2,105z M366,104.8c-3.8,1.3-6.9,4.2-8.9,7.7
            c-0.8,4.3-0.7,9.2,2.1,12.8c3.1,4.1,7.6,8.9,13.3,7.3c4.6-1.8,8.4-5.5,10.4-10c0.2-3.6,0.5-7.3-0.2-10.8
            C379.4,106.4,372.2,102.8,366,104.8z M131.1,106c-2.3,1.1-4.5,2.5-6,4.6c-1.7,2.9-0.9,6.4-0.9,9.5c0.4,5.4,4.7,9.3,8.8,12.3
            c4,2.7,9.5,1.2,12.6-2.3c2.4-2.8,5.3-6,4.9-10c-0.1-3.1,0.6-6.6-1.9-9C145,105.2,137.3,102.8,131.1,106z M269.2,105.7
            c0,7.7-0.3,15.7,2.9,22.9c3,5.3,10.7,6.7,15.8,3.5c4.8-2.6,7.9-7.7,8.1-13.1c0.1-3.8,0-7.7,0.1-11.5c-2.3-1.4-4.7-2.6-7.4-2.8
            C282.1,104.1,275.5,104.1,269.2,105.7z M335.9,105.4c-2.7,1.1-4.8,3.2-6.5,5.5c-3,3.2-1.8,8-1.7,12c2.3,5.1,6.5,10.9,12.7,10.6
            c4.2,0.3,7-3.3,9.7-5.9c3.6-3.5,4.1-8.9,3.7-13.7c0.1-2.8-2.1-5-4.3-6.4C345.7,104.8,340.4,103.6,335.9,105.4z M74.7,110.9
            c-1.9-1.2-4.7-3.7-6.6-1.1c-3.7,3-1.8,8.1-2.3,12.2c-0.4,5,3,11.1,8.7,10.7c5.1-0.2,11.8,1.4,15.4-3.2c3.3-4.1,2.1-9.7,2.3-14.5
            c0.5-3.2-2-5.8-4.5-7.3C83.1,106.3,78.3,107.9,74.7,110.9z M385.7,115c0.2,4.8-1,10.3,2.3,14.4c2.3,3.4,6.8,3.3,10.4,3.4
            c3.8-0.1,8.5,0.7,11.2-2.7c4.1-5.1,1.8-11.9,2.4-17.8c-1.3-1.4-2.7-2.7-4.1-4c-2,0.6-4,3.6-6.1,1.7c-2.4-1.7-5.3-2.9-8.3-2.7
            C389.3,106.7,385.3,110.9,385.7,115z M36.7,113.1c-0.6,5.4,1.3,10.8,4.9,14.8c3.4,3.1,8.1,6,12.8,4.3c5.4-2.9,9.3-9,8.8-15.2
            c-0.2-1.6,0.4-3.6-1.1-4.7c-3.5-3.5-9.3-1.8-12.6,1.3c-2.1-1.5-4.4-2.9-7.1-2.9C40.4,111.2,38.5,112.2,36.7,113.1z M415,113.3
            c-0.6,4.4-0.4,9.1,2.2,12.9c2.4,3.3,5.8,7.1,10.3,6.4c6.4-1,11.5-6.3,13.2-12.5c0.8-2.9,1.8-7.7-2.2-8.5
            c-3.4-1.9-7.2-0.3-10.1,1.9C424.9,110.2,418.2,108.8,415,113.3z M457.6,115c-3.3-1.8-7.1-2.3-10.7-1.6c-1.1,0.5-2.8,1.1-2.9,2.6
            c-0.4,5-0.3,11,4.1,14.4c3.8,3.9,9.8,2.8,14.6,1.8c7.7-2.7,10.5-14.3,4.5-19.9C463.8,112.3,460.5,113.3,457.6,115z M7.7,120
            c-0.6,5.8,3.7,12.1,9.7,12.7c4.2,0.8,9.3,1.2,12.5-2.3c4.7-3.6,4.5-10.2,3.8-15.4c-3.7-3.1-9.3-1.9-13.3,0.1
            c-2.8-1.5-5.8-2.9-9.1-2.6C8.5,113.8,7.3,117,7.7,120z" />
                        </g>
                    </a>
                    <path class="st27" d="M395.5,109.9c0.9-3.4,3.8-0.2,5.3,0.8c1.9,2.5,4.3-0.9,6.7-0.3c-1.6,2.2-3.4,4.3-5,6.4
        C400.9,113.8,398.5,111.4,395.5,109.9z" />
                    <path class="st27" d="M17.3,118.1c3.5-2.9,8.1-3.5,12.5-3c-0.2,0.5-0.7,1.7-0.9,2.2c-1.8,0-3.6-0.1-5.3,0.1
        C21.5,117.8,19.4,119.7,17.3,118.1z" />
                </g>
                <g>
                    <a href="#">
                        <g>
                            <path class="comon st28" @click="reply_click(38)"
                                :class="[tooth_num.includes(38)==true  ?  'activeClass comon st3 ' : ' comon st3']"
                                id="38" d="M457.6,115c2.9-1.8,6.2-2.8,9.7-2.7c6,5.7,3.2,17.2-4.5,19.9c-4.8,1-10.8,2.2-14.6-1.8
            c-4.4-3.3-4.5-9.4-4.1-14.4c0.1-1.5,1.7-2.1,2.9-2.6C450.5,112.8,454.3,113.3,457.6,115z M448,115.2c0.3,0.7,0.6,1.4,0.9,2.1
            c3.5-0.2,7.1,0,10.2,2c0.4-0.8,0.8-1.5,1.1-2.3C456.3,115.2,452.1,114.4,448,115.2z" />
                        </g>
                    </a>
                </g>
                <a href="#">
                    <g>
                        <path class="st29" d="M448,115.2c4.2-0.8,8.4,0,12.2,1.9c-0.4,0.8-0.8,1.5-1.1,2.3c-3-2-6.7-2.2-10.2-2
        C448.5,116.6,448.2,115.9,448,115.2z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st30"
                            d="M271.4,102.9c6.3-1.6,12.9-1.5,19.3-0.1C284.3,102.3,277.8,102.2,271.4,102.9z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="comon st31" @click="reply_click(32)"
                            :class="[tooth_num.includes(32)==true  ?  'activeClass comon st3 ' : ' comon st3']" id="32"
                            d="M269.2,105.7c6.3-1.6,12.9-1.5,19.3-1c2.7,0.2,5.2,1.4,7.4,2.8c-0.1,3.8,0,7.7-0.1,11.5
        c-0.2,5.4-3.3,10.5-8.1,13.1c-5,3.1-12.8,1.8-15.8-3.5C268.9,121.4,269.2,113.3,269.2,105.7z" />
                    </g>
                </a>
                <g>
                    <a href="#">
                        <g>
                            <path class="comon st32" @click="reply_click(46)"
                                :class="[tooth_num.includes(46)==true  ?  'activeClass comon st3 ' : ' comon st3']"
                                id="46" d="M74.7,110.9c3.6-3,8.4-4.6,13-3.3c2.5,1.5,4.9,4.1,4.5,7.3c-0.3,4.8,1,10.5-2.3,14.5
            c-3.6,4.6-10.3,3-15.4,3.2c-5.6,0.4-9.1-5.7-8.7-10.7c0.4-4.1-1.4-9.2,2.3-12.2C70,107.2,72.8,109.8,74.7,110.9z M75.6,111.7
            c-1.7-0.5-3.3-1.5-5.1-1.3c1.6,2.2,3.4,4.3,5,6.4c1.6-2.9,4-5.4,7-6.9C80.9,106.2,77.7,110.8,75.6,111.7z" />
                        </g>
                    </a>
                </g>
                <g>

                    <a href="#">
                        <g>
                            <path class="comon st33" @click="reply_click(36)"
                                :class="[tooth_num.includes(36)==true ?  'activeClass comon st3 ' : ' comon st3']"
                                id="36"
                                d="M385.7,115c-0.4-4.1,3.6-8.3,7.8-7.8c3-0.3,5.8,1,8.3,2.7c2.1,1.9,4.1-1.1,6.1-1.7c1.5,1.2,2.8,2.6,4.1,4
            c-0.6,5.9,1.7,12.7-2.4,17.8c-2.7,3.4-7.4,2.6-11.2,2.7c-3.6,0-8.1,0-10.4-3.4C384.7,125.4,385.9,119.8,385.7,115z M395.5,109.9
            c3,1.5,5.4,3.9,7,6.8c1.7-2.2,3.4-4.2,5-6.4c-2.4-0.6-4.8,2.8-6.7,0.3C399.3,109.7,396.4,106.5,395.5,109.9z" />
                        </g>
                    </a>

                </g>
                <a href="#">
                    <g>

                        <path class="comon st34" @click="reply_click(47)"
                            :class="[tooth_num.includes(47)==true ?  'activeClass comon st3 ' : ' comon st3']" id="47"
                            d="M36.7,113.1c1.9-0.9,3.8-1.9,5.8-2.4c2.6-0.1,5,1.4,7.1,2.9c3.3-3,9.1-4.7,12.6-1.3c1.5,1.1,0.9,3.1,1.1,4.7
        c0.4,6.2-3.4,12.3-8.8,15.2c-4.7,1.7-9.4-1.2-12.8-4.3C38,123.9,36,118.5,36.7,113.1z" />
                        <path class="comon st35" @click="reply_click(37)"
                            :class="[tooth_num.includes(37)==true ?  'activeClass comon st3 ' : ' comon st3']" id="37"
                            d="M415,113.3c3.2-4.6,10-3.2,13.5,0.3c2.8-2.2,6.6-3.8,10.1-1.9c4,0.8,3,5.6,2.2,8.5
        c-1.7,6.2-6.8,11.5-13.2,12.5c-4.5,0.7-8-3.1-10.3-6.4C414.6,122.4,414.4,117.7,415,113.3z" />
                    </g>
                </a>
                <g>
                    <a href="#">
                        <g>
                            <path class="comon st36" @click="reply_click(48)"
                                :class="[tooth_num.includes(48)==true ?  'activeClass comon st3 ' : ' comon st3']"
                                id="48" d="M7.7,120c-0.4-3,0.9-6.2,3.7-7.6c3.3-0.3,6.3,1.2,9.1,2.6c4-1.9,9.6-3.2,13.3-0.1c0.6,5.3,0.9,11.8-3.8,15.4
            c-3.2,3.4-8.3,3-12.5,2.3C11.4,132.1,7.1,125.8,7.7,120z M17.3,118.1c2.1,1.6,4.2-0.2,6.3-0.6c1.8-0.2,3.6-0.1,5.3-0.1
            c0.2-0.6,0.7-1.7,0.9-2.2C25.4,114.5,20.8,115.1,17.3,118.1z" />
                        </g>
                    </a>
                </g>
                <a href="#">
                    <g>
                        <path class="st0" d="M210.6,122.2c2.9,6.4,7.8,12.9,15,14.4c6.1,0.5,10.3-5,13.3-9.5c3.3,4.4,7.3,10,13.5,9.6
        c7.4-1.5,12-8.2,15.1-14.6c1.1,3.6,1.7,7.7,4.7,10.2c2.9,2.4,6.6,3.9,10.3,3.7c6-0.7,11.1-4.6,14.2-9.7c1.8,5.3,5.2,10.3,10.6,12.2
        c-1.9,0.5-3.9,1-5.9,0.9c-39.7-0.1-79.4-0.1-119-0.1c-4.3,0-8.7,0.5-12.9-0.8c6.1-1.4,9.8-6.7,11.6-12.3
        c4.2,6.4,12.4,11.8,20.2,8.4C206.9,133,209.3,127.4,210.6,122.2z" />
                    </g>
                </a>
                <a href="#">
                    <g>
                        <path class="st0" d="M36.1,124.4c3.2,5.9,9.4,10.9,16.5,10.2c4.9-0.1,8-4.5,11-7.8c1.8,2.8,3.4,6.4,6.9,7.2
        c5.8,1.5,12.2,1.4,17.9-0.5c2.9-1,4.3-4,5.9-6.4c3.8,3.5,7.8,7.8,13.3,7.8c6.7,0.3,11.4-5.5,14.7-10.6c3.5,5.4,8.3,11.2,15.2,11.7
        c6.3,0.3,10.8-4.9,14.2-9.6c3.2,5.8,8.5,10.2,14.8,12.3c-1.6,0.5-3.2,0.8-4.9,0.9c-51.8-0.1-103.7,0-155.5-0.1
        c-0.1-3.6-0.2-7.4,0.9-10.9c4.2,6.2,12.4,7.7,19.4,6.3C31.3,133.7,35,129.4,36.1,124.4z" />
                        <path class="st0" d="M341.6,135.9c6.3-1.1,10.8-6.4,14-11.6c3.6,4.9,8.1,11,14.9,10.5c5.5,0,9.5-4.2,13.1-7.7
        c1.7,2.7,3.5,6,6.9,6.8c5.8,1.6,12.1,1.7,17.9-0.2c3.3-1.1,4.4-4.5,5.8-7.3c3.1,3.9,6.7,8.8,12.3,8.3c6.6,0.3,11.7-4.6,15.2-9.6
        c1.5,3.2,3,6.6,6.3,8.3c6.8,3.6,15.8,2.2,21.6-2.8l1.6-1.1c0.1,3.3,0.1,6.6,0,9.9c-48.6,0-97.2,0-145.8,0c-4.7-0.1-9.5,0.6-14-0.9
        c6.3-2.1,11.9-6.3,14.9-12.3C329.8,131.3,334.9,136.7,341.6,135.9z" />
                    </g>
                </a>
                <rect x="12.7" y="79.3" width="455.6" height="0.9" />
                <rect x="440.2" y="63.4" width="0.9" height="34.7" />
                <rect x="412.5" y="62.8" width="0.9" height="34.7" />
                <rect x="382.7" y="63.1" width="0.9" height="34.7" />
                <rect x="355" y="62.5" width="0.9" height="34.7" />
                <rect x="323.2" y="62.4" width="0.9" height="34.7" />
                <rect x="295.5" y="61.8" width="0.9" height="34.7" />
                <rect x="265.7" y="62.1" width="0.9" height="34.7" />
                <rect x="238" y="61.5" width="0.9" height="34.7" />
                <rect x="207.8" y="62.4" width="0.9" height="34.7" />
                <rect x="180.1" y="61.9" width="0.9" height="34.7" />
                <rect x="150.3" y="62.1" width="0.9" height="34.7" />
                <rect x="122.6" y="61.6" width="0.9" height="34.7" />
                <rect x="91.7" y="61.4" width="0.9" height="34.7" />
                <rect x="33.3" y="61.1" width="0.9" height="34.7" />
                <rect x="63.1" y="60.8" width="0.9" height="34.7" />
                <text transform="matrix(1 0 0 1 22.3 73.9892)" :class="['st39', 'st40', textClass]">18</text>
                <text transform="matrix(1 0 0 1 55.4868 73.9892)" :class="['st39', 'st40', textClass]">17</text>
                <text transform="matrix(1 0 0 1 84.0802 73.9892)" :class="['st39', 'st40', textClass]">16</text>
                <text transform="matrix(1 0 0 1 114.6549 75)" :class="['st39', 'st40', textClass]">15</text>
                <text transform="matrix(1 0 0 1 144.7977 75)" :class="['st39', 'st40', textClass]">14</text>
                <text transform="matrix(1 0 0 1 169.5968 74.9999)" :class="['st39', 'st40', textClass]">13</text>
                <text transform="matrix(1 0 0 1 198.8225 74.9999)" :class="['st39', 'st40', textClass]">12</text>
                <text transform="matrix(1 0 0 1 229.3609 74.9999)" :class="['st39', 'st40', textClass]">11</text>
                <text transform="matrix(1 0 0 1 256.9392 75)" :class="['st39', 'st40', textClass]">21</text>
                <text transform="matrix(1 0 0 1 286.082 75)" :class="['st39', 'st40', textClass]">22</text>
                <text transform="matrix(1 0 0 1 316.4399 73.0106)" :class="['st39', 'st40', textClass]">23</text>
                <text transform="matrix(1 0 0 1 343.2068 73.0106)" :class="['st39', 'st40', textClass]">24</text>
                <text transform="matrix(1 0 0 1 373.6457 73.0106)" :class="['st39', 'st40', textClass]">25</text>
                <text transform="matrix(1 0 0 1 403.5746 73.0106)" :class="['st39', 'st40', textClass]">26</text>
                <text transform="matrix(1 0 0 1 433.4513 73.0106)" :class="['st39', 'st40', textClass]">27</text>
                <text transform="matrix(1 0 0 1 464.0921 73.0106)" :class="['st39', 'st40', textClass]">28</text>
                <text transform="matrix(1 0 0 1 22.3 93.4785)" :class="['st39', 'st40', textClass]">48</text>
                <text transform="matrix(1 0 0 1 55.4868 93.4785)" :class="['st39', 'st40', textClass]">47</text>
                <text transform="matrix(1 0 0 1 84.0802 93.8741)" :class="['st39', 'st40', textClass]">46</text>
                <text transform="matrix(1 0 0 1 114.6549 94.4892)" :class="['st39', 'st40', textClass]">45</text>
                <text transform="matrix(1 0 0 1 144.7977 95.0126)" :class="['st39', 'st40', textClass]">44</text>
                <text transform="matrix(1 0 0 1 169.5968 94.4892)" :class="['st39', 'st40', textClass]">43</text>
                <text transform="matrix(1 0 0 1 198.8225 94.4891)" :class="['st39', 'st40', textClass]">42</text>
                <text transform="matrix(1 0 0 1 229.3609 94.489)" :class="['st39', 'st40', textClass]">41</text>
                <text transform="matrix(1 0 0 1 256.9392 94.4892)" :class="['st39', 'st40', textClass]">31</text>
                <text transform="matrix(1 0 0 1 316.4399 94.4892)" :class="['st39', 'st40', textClass]">33</text>
                <text transform="matrix(1 0 0 1 286.082 95.0127)" :class="['st39', 'st40', textClass]">32</text>
                <text transform="matrix(1 0 0 1 343.2068 95.0127)"   :class="['st39', 'st40', textClass]">34</text>
                <text transform="matrix(1 0 0 1 373.6457 95.5)"  :class="['st39', 'st40', textClass]" >35</text>
                <text transform="matrix(1 0 0 1 403.5746 95.4998)" :class="['st39', 'st40', textClass]">36</text>
                <text transform="matrix(1 0 0 1 433.4513 95.4999)" :class="['st39', 'st40', textClass]">37</text>
                <text transform="matrix(1 0 0 1 464.0921 95.4999)" :class="['st39', 'st40', textClass]">38</text>
            </svg>

        </div>
    </div>
</template>

<style>
    .st0 {
        fill: #F37574;
    }

    .st1 {
        fill: #1E1B19;
    }

    .comon {
        fill: #fff;
    }

    .st39 {
        font-family: 'TypoRoundBoldDemo';
    }

    .st40 {
        font-size: 12px;
    }

    .comon:hover {
        fill: aqua;
    }

    .activeClass {
        fill: aqua;
    }


.text-light-mode {
fill: #000;
}

.text-dark-mode {
    fill: #fff; /* White for dark mode */
}
</style>

<script>
    import {
        EventBus
    } from "../../views/dashboard/event-bus.js";
    export default {
        props: {
            tooth_num: Array,
            id: Number
        },
        data() {
            return {
                active: '',

            }
        },

        computed: {
    // Computed property to return the correct class based on the current theme
    textClass() {
      
      return this.$vuetify.theme.dark ? 'text-dark-mode' : 'text-light-mode';
    },
  },
        methods: {
            reply_click(id) {


                function isValidJSON(jsonString) {
                    try {
                        JSON.parse(jsonString);
                        return true;
                    } catch (error) {
                        return false;
                    }
                }


                if (isValidJSON(this.tooth_num)) {


                    if (this.id !== undefined) {
                        this.tooth_num = JSON.parse(this.tooth_num);
                    }
                    if (this.tooth_num.includes(id)) {

                        var carIndex = this.tooth_num.indexOf(id);
                        this.tooth_num.splice(carIndex, 1);
                    } else {
                        this.tooth_num.push(id);
                        EventBus.$emit("changetooth", this.tooth_num);
                    }


                } else {
                    this.tooth_num

                    if (this.tooth_num.includes(id)) {

                        carIndex = this.tooth_num.indexOf(id); //get  "car" index
                        //remove car from the colors array
                        this.tooth_num.splice(carIndex, 1); //
                    } else {

                        this.tooth_num.push(id);
                        EventBus.$emit("changetooth", this.tooth_num);
                    }

                }




            }
        }
    }
</script>